import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoggedInUser } from '../../_store/app.selectors';
import { tap, take, switchMap } from 'rxjs/operators';
import { AppState } from '../../_store/app-state.model';
import { environment } from '../../../environments/environment';
import { RoutingConfigService } from '../services/services-index';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private readonly routingConfigService: RoutingConfigService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.applyAuthGuard(route, state);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.applyAuthGuard(route, state);
	}

	private applyAuthGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(selectLoggedInUser).pipe(
			take(1),
			switchMap(user => {
				if (!user || !user.isAuthenticated) {
					return of(false).pipe(
						tap(() => {
							environment.loginRedirectUrl
								? window.location.href = environment.loginRedirectUrl
								: this.router.navigate(['/login']);}
						)
					);
				}
				else if (user.isAuthenticated) {
					return this.validateRoute(route, state);
				}
			})
		);
	}

	private validateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const routeUrl = this.constructRouteUrl(route, state.url);

		if (this.routingConfigService.userRouteUrls.includes(routeUrl)) {
			return of(true);
		}
		else {
			console.log('Not authorized to access [' + routeUrl + ']',);
			return of(false).pipe(
				tap(() => this.router.navigate([this.routingConfigService.defaultRoute]))
			);
		}
	}

	private constructRouteUrl(route: ActivatedRouteSnapshot, url: string): string {
		const routeParams = Object.keys(route.params || {});

		// If there are no parameters in the route, return the url as is
		if (routeParams.length === 0) {
			return url;
		}
		else {
			// Get the last part of the route and replace parameter values with parameter names
			const urlParts = url.split('/');
			const index = urlParts.indexOf(route.routeConfig.path.split('/')[0]);

			if (index === -1) {
				return url;
			}
			else {
				// get the url up to the parameters
				let routeUrl = index === -1 ? url : `${urlParts.slice(0, index + 1).join('/')}`;

				// add the parameter names to the route url for matching purposes
				routeParams.forEach(param => {
					routeUrl += `/:${param}`;
				})

				return routeUrl;
			}
		}
	}
}
